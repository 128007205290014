// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
@import "./app/colors";

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$roldriveadmin-primary: mat.define-palette($custom-brand-primary);
$roldriveadmin-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$roldriveadmin-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".

$roldriveadmin-theme: mat.define-light-theme(
  (
    color: (
      primary: $roldriveadmin-primary,
      accent: $roldriveadmin-accent,
      warn: $roldriveadmin-warn,
    ),
  )
);

@include mat.all-component-themes($roldriveadmin-theme);

html,
body {
  height: 100%;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

*:focus,
textarea:focus {
  outline: none !important;
}

button:focus-visible {
  outline: none !important;
  border: 0 !important;
  box-shadow: 0 0 0 transparent !important;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}

button:focus,
textarea.form-control:focus,
.btn:focus,
.btn:active,
button:active,
input,
:active {
  outline: none;
  box-shadow: 0 0 0 white;
  outline: 0 !important;
  box-shadow: none !important;
}

*:focus:not(.focus-visible) {
  outline: none;
}

.clear_all {
  float: none;
  clear: both;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 40px white inset !important;
}

body {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  overflow-x: hidden;
  color: #000;
  font-weight: 400;
}

a {
  transition: all 500ms;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

$orange: #ec5c29;
$indigo: #a3aed0;
$yellow: #e8c13a;
$gray: #aeaeae;
$darkgray: #403d51;
$lightgray: #f5f5f5;
$fontgray: #797979;
$fontblack:#3B3B3B;

.lightgray_bg {
  background-color: $lightgray !important;
}

.bg-black {
  background-color: #000;
}

.bg-lightorange{
  background-color: #FFF2ED;
}

.text-warning {
  color: $orange !important;
}

.text-indigo {
  color: $indigo !important;
}

.text-yellow {
  color: $yellow !important;
}

.text-gray {
  color: $gray !important;
}

.text-black {
  color: #000 !important;
}

.text-lg-black {
  color: $fontblack !important;
}

.text-darkgray {
  color: $darkgray !important;
}

.text-brown {
  color: #b72c00 !important;
}

.text-green {
  color: #028a10 !important;
}

.text-white {
  color: #fff !important;
}

.txt_input_color {
  color: #000;
}

.text-fontgray{
  color: $fontgray;
}
.mat-mdc-form-field-infix {
  min-height: 0 !important;
}
.mat-mdc-form-field {
  display: flex;
}

.bdr-gray {
  border-color: #737b7d !important;
}

.bdr-lightgray {
  border-color: #dedede !important;
}

.bdr-orange {
  border-color: $orange !important;
}

.mat-mdc-icon-button .mat-mdc-button-persistent-ripple {
  &::before {
    display: none !important;
  }
}

.mat-mdc-icon-button .mat-mdc-button-ripple {
  display: none !important;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 10px;
  padding-bottom: 10px;
}

.mat-mdc-select-arrow {
  display: block;
  content: url("assets/images/icons/select_icon.png");
}

.bg_transparent {
  background-color: transparent !important;
}

.bg_orange {
  background-color: $orange !important;
}

.bg_darkblue {
  background-color: $orange !important;
}

.bg_lightgray {
  background-color: #f6f6f6 !important;
}

.bg_green {
  background-color: #028a10 !important;
}

.bg_brown {
  background-color: #b72c00 !important;
}

.material-icons.md-18 {
  font-size: 18px;
}
.material-icons.md-24 {
  font-size: 24px;
}
.material-icons.md-36 {
  font-size: 36px;
}
.material-icons.md-48 {
  font-size: 48px;
}

.mat-toolbar {
  color: rgba(0, 0, 0, 0.26);
  background: transparent !important;
  padding: 0 0 0 0 !important;
  a {
    color: rgba(0, 0, 0, 0.26);
    &:hover,
    &:focus {
      color: $orange !important;
    }
  }

  h1 {
    letter-spacing: -0.02em !important;
    color: #000 !important;
  }
}

.mat-drawer-side {
  border-right: 0 !important;
}

.border-white {
  border-color: white;
}

.user_wrapper {
  background-color: white;
  border-radius: 30px;
  padding: 10px 15px;

  i {
    color: #a3aed0;
  }
}

table.user_mg_table tbody tr {
  background: #f6f6f6 !important;
  border-bottom: 5px solid #fff;
}

.social_ul {
  background-color: #f5f5f5;
  li a {
    color: $orange;

    &:hover {
      color: black;
    }
  }
}
app-sidebar ul li {
  &:hover {
    border-color: $orange;
  }
  border-color: transparent;
  color: $orange;
  &.active {
    border-color: $orange;
    span {
      color: $orange;
    }
  }
  &.active i {
    color: $orange;
  }
}

app-sidebar ul li span {
  color: rgba(0, 0, 0, 0.5);
  &:hover,
  &:focus {
    border-color: $orange;
  }

  &:hover i,
  &:focus i {
    color: $orange;
  }
}

.mat-drawer-container {
  color: #000;
}

mat-dialog-container app-form-input * {
  background-color: transparent !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%),
    0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
  background: #fff;
  color: black;

  i.fa-circle-xmark {
    color: $orange !important;

    &:hover {
      color: black !important;
    }
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(255, 255, 255, 0.8) !important;
}

.cdk-global-overlay-wrapper {
  overflow: auto;
  padding: 0; 
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: $orange;
}

.common_btn {
  background-color: $orange !important;
  color: #ffffff !important;
  &:hover,
  &:focus {
    background-color: black !important;
  }
}

.mat-mdc-radio-button {
  .mat-radio-ripple {
    display: none !important;
  }
}

.mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio__background::before,
.mat-mdc-radio-button
  .mdc-radio:hover
  .mdc-radio__native-control:not([disabled]):not(:focus)
  ~ .mdc-radio__background::before {
  display: none !important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__background::before {
  background-color: transparent !important;
}

.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled:not(:checked)
  + .mdc-radio__background
  .mdc-radio__outer-circle {
  border-color: #cacaca !important;
}

.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled:checked
  + .mdc-radio__background
  .mdc-radio__outer-circle,
.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled
  + .mdc-radio__background
  .mdc-radio__inner-circle {
  border-color: $orange !important;
}

.mat-mdc-radio-button {
  .mdc-form-field {
    color: #000 !important;
  }
}

.or_sep {
  &::after {
    content: "";
    display: block;
    width: 170px;
    height: 1px;
    background-color: #737b7d !important;
    position: absolute;
    right: -190px;
    top: 15px;
  }

  &::before {
    content: "";
    display: block;
    width: 170px;
    height: 1px;
    background-color: #737b7d !important;
    position: absolute;
    left: -190px;
    top: 15px;
  }
}

.common_btn_white {
  color: $orange !important;
  border-color: $orange;
  background-color: white !important;
  &:hover,
  &:focus {
    background-color: black !important;
    border-color: black !important;
    color: white !important;
  }
}

.common_btn_black {
  background-color: black !important;
  color: #ffffff !important;
  &:hover,
  &:focus {
    background-color: $orange !important;
  }
}

.common_btn_bdr {
  background-color: transparent !important;
  color: $orange !important;
  border-color: $orange;
  &:hover,
  &:focus {
    background-color: white !important;
  }
}
.mdc-line-ripple {
  display: none;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled)
  .mdc-line-ripple::before {
  border-bottom-color: #cacaca;
}

.mat-mdc-text-field-wrapper {
  background-color: white;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__trailing {
  border-color: #cacaca;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(
    .mdc-text-field--focused
  ):hover
  .mdc-notched-outline
  .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(
    .mdc-text-field--focused
  ):hover
  .mdc-notched-outline
  .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(
    .mdc-text-field--focused
  ):hover
  .mdc-notched-outline
  .mdc-notched-outline__trailing {
  border-color: #cacaca !important;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  border-color: #cacaca !important;
}

.mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--focused .mdc-notched-outline__trailing {
  border-width: 1px !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__trailing {
  border-color: $orange !important;
  // box-shadow: 0 0 5px $orange !important;
  transition: box-shadow 400ms, border-color 400ms !important;
}

.mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: $orange;
  border-bottom-width: 1px;
}

.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: #000;
  opacity: 1;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent;
  // border: 1px solid #ddd;
}

.mat-mdc-form-field-error {
  color: #ff0000;
  font-size: 11px;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #000;
}

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: $orange;
}

.user_txt_bg {
  background-color: #f4f7fe;
}

.mat-mdc-select-arrow svg {
  fill: $orange !important;
}

.mat-mdc-select-placeholder {
  color: rgba(0, 0, 0, 1);
}

.mdc-data-table__cell,
.mdc-data-table__header-cell {
  border-bottom-width: 0 !important;
  border-bottom-style: solid;
  padding: 0 8px 0 8px !important;
}

.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked
  ~ .mdc-checkbox__background,
.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:indeterminate
  ~ .mdc-checkbox__background,
.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control[data-indeterminate="true"]:enabled
  ~ .mdc-checkbox__background {
  border-color: $orange !important;
  background-color: $orange !important;
}

.mat-mdc-option.mdc-list-item--disabled {
  opacity: 1 !important;
}

.mat-mdc-checkbox-ripple,
.mdc-checkbox__ripple {
  display: none !important;
}

.contact_img {
  height: 160px;
  object-fit: cover;
}

.mdc-menu-surface--open {
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.25) !important;
  border-radius: 8px !important;
}

.mat-mdc-menu-item {
  height: 40px !important;
  min-height: 40px !important;
}

.mat-mdc-menu-item:hover:not([disabled]) {
  background: #f2f2f2 !important;
}

.mat-mdc-paginator-container {
  padding: 0 !important;
}

.connecting-list li {
  position: relative;
}

.connecting-list li:not(.drop-location)::before {
  content: "";
  position: absolute;
  left: -3.5px;
  top: 12px;
  height: 100%;
  bottom: 0;
  width: 1px; /* Adjust as needed */
  border-left: 1px dotted #000; /* Adjust as needed */
  z-index: 1;
}

.connecting-list li.drop-location {
  border-left-width: 0 !important; /* Remove left border from the specified li elements */
}



.address_booking {
  li:not(:last-child) {
    &:after {
      content: "";
      display: block;
      height: 79%;
      width: 1px;
      border: 1px dotted #000;
      position: absolute;
      left: 4px;
      top: 12px;
      bottom: 0;
    }
  }
}

.address_booking {
  li {
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.address_statement {
  li {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.address_statement {
  li:first-child {
    &:after {
      content: "";
      display: block;
      height: 100%;
      width: 1px;
      border: 1px dotted #000;
      position: absolute;
      left: 4px;
      top: 10px;
    }
    @media only screen and (max-width: 560px) {
      content: "";
      display: block;
      height: 83%;
      width: 1px;
      border: 1px dotted #000;
      position: absolute;
      left: -4px;
      top: 8px;
      bottom: 0;
    }
  }
}

.statement_col:after {
  background-color: #dedede;
  width: 1px;
  height: 100%;
  content: "";
  position: absolute;
  right: 0;
  top: 0;
}

.statement_col:last-child:after {
  display: none;
}

.select_veh_modal_sep:after {
  background-color: #dedede;
  width: 1px;
  height: 95%;
  content: "";
  position: absolute;
  right: -10px;
  top: 0;
}
.summary .booking_col{
  position: relative;

}
.summary .booking_col::after{
  background-color: #dedede;
  width: 1px;
  height: 75%;
  content: "";
  position: absolute;
  right: -6px;
  top: 50%;
  transform: translateY(-50%);
}

.booking_col:after {
  background-color: #dedede;
  width: 1px;
  height: 75%;
  content: "";
  position: absolute;
  right: -6px;
  top: 50%;
  transform: translateY(-50%);
}

.booking_col:last-child:after {
  display: none;
}

.mdc-switch__handle * {
  display: none !important;
}

.mat-mdc-slide-toggle .mdc-switch {
  width: 50px !important;
}

.mat-mdc-slide-toggle .mdc-switch .mdc-switch__track {
  height: 35px !important;
}

.mdc-switch__track::before,
.mdc-switch__track::after {
  border-radius: 20px !important;
}

.mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle {
  width: 15px !important;
}

.mat-mdc-slide-toggle .mdc-switch .mdc-switch__focus-ring-wrapper,
.mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle {
  height: 15px !important;
}

.mat-mdc-slide-toggle
  .mdc-switch.mdc-switch--unselected:enabled
  .mdc-switch__handle::after,
.mat-mdc-slide-toggle
  .mdc-switch.mdc-switch--selected:enabled
  .mdc-switch__handle::after {
  background: white !important;
}

.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::before {
  background: #2c2c2c !important;
}

.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after {
  background: #028a10 !important;
}

.mdc-switch__handle {
  left: 7px !important;
}

.mat-mdc-slide-toggle-checked .mdc-switch__handle {
  left: -3px !important;
}

.supplier_details_right {
  background-color: #f0e1e1;
  border-color: $orange;
}

.textarea-style .mdc-text-field {
  padding: 0 !important;
  border: 0;
}

.scrollDesign::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

/* Track */
.scrollDesign::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.scrollDesign::-webkit-scrollbar-thumb {
  background: rgb(204, 204, 204);
  border-radius: 10px;
}

/* Handle on hover */
.scrollDesign::-webkit-scrollbar-thumb:hover {
  background: #ec5c29;
}
.scrollDesign {
  .mat-drawer-inner-container::-webkit-scrollbar {
    width: 5px;
    height: 10px;
  }

  /* Track */
  .mat-drawer-inner-container::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  .mat-drawer-inner-container::-webkit-scrollbar-thumb {
    // background: #ec5c29;
    border-radius: 10px;
  }

  /* Handle on hover */
  .mat-drawer-inner-container::-webkit-scrollbar-thumb:hover {
    background: #ec5c29;
  }
  .mat-drawer-inner-container {
    overflow-y: auto !important;
    overflow-x: hidden;
  }
  .mat-expansion-panel-body {
    padding: 0 !important;
  }
}

@media only screen and (max-width: 767px) {
  .user_wrapper {
    padding: 5px 10px;
  }
  .cdk-overlay-panel {
    left: 20 !important;
    right: 0px !important;
    width: 90% !important;
  }
  .mdc-menu-surface.mat-mdc-select-panel {
    width: 100% !important;
    max-width: 250px !important;
  }
}

.side-nav-color {
  .mat-expansion-indicator::after {
    color: #ec613d !important;
  }
}

/* Error */
.snackbar-type-fill-error {
  background-color: #b72c00 !important;
}

$custom-primary: #ec613d; // Set your custom primary color here

.mat-progress-spinner.custom .mat-progress-spinner-circle {
  stroke: $custom-primary;
}

/*******
  using global design for error & success message component

**/
.mat-mdc-snack-bar-container {
  &.red-snackbar {
    --mdc-snackbar-container-color: #f92c2c;
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
    max-width: 300px;
  }
}
.mat-mdc-snack-bar-container {
  &.error-snackbar {
    --mdc-snackbar-container-color: #a70d0d !important;
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
  }
  &.green-snackbar {
    --mdc-snackbar-container-color: #4d9d18;
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
  }
  &.width-snackbar {
    width: 100px;
    margin-right: 120px;
  }
  .actiondropdown .mat-mdc-select-panel {
    margin-left: -85px !important;
    width: 200px !important;
  }
}
@media only screen and (max-width: 560px) {
  // .cdk-overlay-pane {
  //   min-width: 90px !important;
  // }
  .address_booking {
    li:not(:last-child) {
      &:after {
        content: "";
        display: block;
        height: 88%;
        width: 1px;
        border: 1px dotted #000;
        position: absolute;
        left: -4px;
        top: 10px;
        bottom: 0;
      }
    }
  }

  .connecting-list li:not(.drop-location)::before {
    left: -8px;
    top: 8px;
    height: 98%;
  }
  .mat-mdc-dialog-content {
    max-height: 80vh !important;
  }
}
.placeholder-color::placeholder {
  color: #000 !important;
}

.preformatted-tooltip {
  --mdc-plain-tooltip-container-color: #fff;
  --mdc-plain-tooltip-supporting-text-color: #5b5b5b;
  .mdc-tooltip__surface{
    // white-space: pre-wrap; /* Preserve line breaks and spacing */
    font-size: 14px; /* Adjust the font size as needed */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    padding: 5px;
    text-align: left;
  }
}
// Status BG color and color
.colorGreen {
  color: #1fac39;
  background-color: #1fac3969;
}
.colorRed {
  color: #d50000;
  background-color: #d5000066;
}
.colorYellow {
  color: #c99b25;
  background-color: #d39f1b4f;
}
.colorBlue {
  color: #1b4b92;
  background-color: #4d6e8f85;
}
.colorCancelled {
  color: #f84545;
  background-color: #f8454557;
}

// Error border for phone number
.input-invalid .iti {
  border: 1px solid red;
  border-radius: 5px;
}

.statement-summary {
  .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(
      .mdc-text-field--textarea
    )
    .mat-mdc-form-field-infix {
    padding: 10px 5px !important;
  }
  .mdc-text-field {
    padding: 0 8px;
  }
}

.statement-summary .mat-mdc-form-field-flex{
  border: 1px solid #CACACA;
  border-radius: 5px;
}

.mat-statement-date .mat-mdc-icon-button.mat-mdc-button-base{
  height: 43px !important
}

.location-region .mat-mdc-select-arrow-wrapper{
  height: 20px !important
}

